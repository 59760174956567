<template>
    <el-row :gutter="20" v-loading="process.loading">
        <el-form :model="single" label-width="100px" ref="editForm" :rules="lessonRules">
            <el-tabs type="" v-model="tab" tab-position="top" style="padding-left: 20px;padding-right: 20px;"
                     @tab-click="handleTabClick">
                <el-tab-pane label="基本信息">
                    <el-row style="padding-right: 20px;" v-if="fields.street">
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.street" field="street">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.category_id" field="category_id">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <el-row type="flex">
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.academy_id"
                                                              field="academy_id">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.course_name"
                                                      field="course_name">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.course_number"
                                                      field="course_number">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.brief" field="brief">
                            </front-single-form-editor>
                        </el-col>

                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.teacher_id" field="teacher_id">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.assistant_id"
                                                      field="assistant_id">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <el-row type="flex">
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.teacher_bio"
                                                              style="margin-bottom: 0"
                                                              field="teacher_bio">
                                    </front-single-form-editor>
                                </el-col>
                                <el-button type="info" plain size="small" style="margin-left: 10px;"
                                           @click="loadTeacherBio">载入简介
                                </el-button>
                            </el-row>
                        </el-col>
                        <el-col :span="12">
                            <el-row type="flex" class="mt-3">
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.picture_data"
                                                              style="margin-bottom: 0"
                                                              field="picture_data">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="12">
                            <el-row type="flex" class="mt-3">
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.no_playback"
                                                              field="no_playback">
                                    </front-single-form-editor>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row style="padding-left: 20px">
                        <el-button size="medium" type="primary" icon="el-icon-check" @click="editLesson"
                                   v-if="single.is_closed + '' !== '1'">保存课程
                        </el-button>
                        <el-button size="medium" type="default" icon="el-icon-close" @click="$router.back()">放弃保存
                        </el-button>
                        <el-button size="medium" type="success" icon="el-icon-refresh" @click="rebuildLesson"
                                   v-if="single.is_closed + '' === '1'">重建会议号
                        </el-button>
                        <small class="ml-3 text-info" v-if="single.is_closed === '1'"> | 该课程已经结束，如要排课，请点击保存课程重建会议号。</small>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="排课设置" :disabled="meetingClosedOrNotExists">
                    <el-card class="mb-3" :body-style="{ padding: '0px' }" shadow="none">
                        <el-table
                            :data="filter_plans"
                            size="medium"
                            :border="true"
                            style="width: 100%">
                            <el-table-column width="120px" label="操作">
                                <div slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-button
                                            size="mini" icon="el-icon-check" type="default"
                                            @click="planSave(scope.$index, scope.row)"></el-button>
                                        <el-button
                                            size="mini" icon="el-icon-close" type="danger"
                                            @click="planCancel(scope.$index, scope.row)"></el-button>
                                    </div>
                                    <div v-else>
                                        <el-button :disabled="plan_in_edit"
                                                   v-if="scope.row.id >=0"
                                                   size="mini" icon="el-icon-edit" type="default"
                                                   @click="planEdit(scope.$index, scope.row)"></el-button>
                                        <el-button :disabled="plan_in_edit"
                                                   v-if="scope.row.id >=0" plain
                                                   size="mini" icon="el-icon-delete" type="danger"
                                                   @click="planDelete(scope.$index, scope.row)"></el-button>
                                    </div>
                                </div>
                            </el-table-column>
                            <el-table-column
                                :width="370"
                                label="开始结束日期">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-date-picker
                                            v-model="scope.row['date']"
                                            type="daterange"
                                            :range-separator="('-')"
                                            size="small"
                                            :start-placeholder="('选择开始日期')"
                                            value-format="yyyy-MM-dd"
                                            :end-placeholder="('选择结束日期')">
                                        </el-date-picker>
                                    </div>
                                    <div v-else>
                                        <el-button type="text" icon="el-icon-plus" v-if="scope.row.id === -1"
                                                   :disabled="plan_in_edit"
                                                   @click="planEdit(scope.$index, scope.row)">添加排课计划
                                        </el-button>
                                        <span v-else>{{ scope.row.date_from }}~{{ scope.row.date_to }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="星期">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-select
                                            clearable filterable
                                            v-model="scope.row['weekdays']"
                                            placeholder="选择星期,日期相同则留空"
                                            multiple
                                            :style="{width:'100%'}">
                                            <el-option v-for="(option) in '一二三四五六日'"
                                                       :label="option" :value="option+''" :key="option"></el-option>
                                        </el-select>
                                    </div>
                                    <div v-else>
                                        {{ scope.row.week_day }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="时间" width="260">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit" class="d-flex">
                                        <el-time-select
                                            v-model="scope.row['time'][0]"
                                            value-format="hh:mm"
                                            :picker-options="{ start: '06:00',step: '00:05',end: '23:45', maxTime:scope.row['time'][1]}"
                                            :placeholder='("开始")'>
                                        </el-time-select>
                                        <span class="mx-2 mt-2">-</span>
                                        <el-time-select
                                            v-model="scope.row['time'][1]"
                                            value-format="hh:mm"
                                            :picker-options="{ start: '06:00',step: '00:05',end: '23:45', minTime:scope.row['time'][0]}"
                                            :placeholder='("结束")'>
                                        </el-time-select>
                                    </div>
                                    <div v-else>
                                        {{ scope.row['date'][0] ? scope.row.time[0] + '-' + scope.row.time[1] : '' }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="循环设置" :width="130">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-select
                                            clearable filterable
                                            v-model="scope.row['odd']"
                                            placeholder="选择循环"
                                            :style="{width:'100%'}">
                                            <el-option v-for="(option,key) in ['每周循环','隔周循环','3周循环']"
                                                       :label="option" :value="key+''" :key="key"></el-option>
                                        </el-select>
                                    </div>
                                    <div v-else>
                                        {{ scope.row['date'][0] ? ['每周循环', '隔周循环', '3周循环'][scope.row.odd] : '' }}
                                    </div>
                                </template>

                            </el-table-column>
                        </el-table>
                        <div class="p-3">
                            <el-button type="primary" icon="el-icon-check" @click="planUpload" :disabled="editing"
                                       :loading="process.loading">保存排课
                            </el-button>
                            <el-button type="default" icon="el-icon-refresh" @click="planReload" :disabled="editing">
                                重新载入
                            </el-button>
                        </div>
                    </el-card>
                    <el-divider class="text-muted">排课详细时间表(<i> 只显示当前学期</i> )</el-divider>
                    <el-table
                        :data="filter_schedule"
                        size="mini"
                        style="width: 100%;">
                        <el-table-column label="暂停" width="80">
                            <template slot-scope="scope">
                                <el-switch size="mini" active-color="#ff4949"
                                           :disabled="scope.row['date'] < today"
                                           :value="pause_dates.includes(scope.row['date']+' '+scope.row['course_time'].substr(0,5))"
                                           @change="handlePauseDateSwitch(scope.row['date']+' '+scope.row['course_time'].substr(0,5))"
                                ></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="date" label="日期" width="120"></el-table-column>
                        <el-table-column prop="course_time" label="时间" width="120"></el-table-column>
                        <el-table-column prop="weekday" label="周" width="120"></el-table-column>
                        <el-table-column prop="reference" label="排课计划"></el-table-column>
                    </el-table>

                </el-tab-pane>
                <el-tab-pane label="会议信息" :disabled="meetingClosedOrNotExists">
                    <el-row>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.tm_meeting_code"
                                                      field="tm_meeting_code">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.host_key"
                                                      field="host_key">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.tm_start_url"
                                                      field="tm_start_url">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.streaming" field="streaming">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <fake-form-item :width="100" label="腾讯会议组">
                                <div class="el-textarea el-input--small" style="height: 36px;">
                                    <div class="el-textarea__inner" style="min-height: 31px;">
                                        {{single.room_group_name}} ｜ <span class="text-info"><i class="el-icon-phone-outline"></i> 如需更改会议组人数大小，请联系管理员。</span>
                                    </div>
                                </div>
                            </fake-form-item>
                        </el-col>
                        <el-col :span="24">
                            <label class="el-form-item__label" style="width: 100px;">二维码:</label>
                            <div class="d-inline-block">
                                <vue-qrcode :value="single.tm_start_url"
                                            :options="{ width: 200,margin:0,color: {dark:'#FFFFFFFF',light:'#333333FF' } }"></vue-qrcode>
                                <br/>互动课堂二维码
                            </div>
                            <div class="d-inline-block ml-3">
                                <vue-qrcode :value="single.streaming"
                                            :options="{ width: 200,margin:0,color: {dark:'#FFFFFFFF',light:'#333333FF' } }"></vue-qrcode>
                                <br/>直播课堂二维码
                            </div>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="课程录像" :disabled="meetingNotCreated">
                    <div>
                        <el-table
                            :data="filter_recordings"
                            size="medium"
                            :border="false"
                            style="">
                            <el-table-column label="操作" width="120">
                                <template slot-scope="scope">
                                    <div>
                                        <el-button
                                            size="small" icon="el-icon-edit" type="default" circle
                                            @click="recordingEdit(scope.$index, scope.row)">
                                        </el-button>
                                        <el-button circle plain
                                                   size="small" icon="el-icon-delete" type="danger"
                                                   @click="recordingDelete(scope.$index, scope.row)">
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="录制时间" width="160px">
                                <template slot-scope="scope">
                                    {{ scope.row.recording_date }}
                                </template>
                            </el-table-column>
                            <el-table-column label="说明">
                                <template slot-scope="scope">
                                    {{ scope.row.memo }}
                                </template>
                            </el-table-column>
                            <el-table-column label="播放地址">
                                <template slot-scope="scope">
                                    <a :href="scope.row.link" target="_blank"> <i class="el-icon-video-play"></i> 播放
                                    </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="80"
                                label="文件ID">
                                <template slot-scope="scope">
                                    {{ scope.row.id }}
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="p-3">
                            <el-button type="primary" icon="el-icon-plus" @click="recordingAdd" :disabled="editing"
                                       :loading="process.loading">上传录像
                            </el-button>
                            <el-button type="default" icon="el-icon-refresh" @click="recordingReload"
                                       :disabled="editing">
                                重新载入
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="云端录像" :disabled="meetingNotCreated">
                    <div>
                        <el-table
                            :data="filter_cloud_records"
                            size="medium"
                            :border="false"
                            style="">
                            <el-table-column label="操作" width="120">
                                <template slot-scope="scope">
                                    <div>
                                        <el-button
                                            size="small" icon="el-icon-edit" type="default" circle
                                            @click="cloudRecordingEdit(scope.$index, scope.row)">
                                        </el-button>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="录制时间">
                                <template slot-scope="scope">
                                    {{ scope.row.record_time }}
                                </template>
                            </el-table-column>
                            <el-table-column label="课程">
                                <template slot-scope="scope">
                                    {{ scope.row.subject }}
                                </template>
                            </el-table-column>
                            <el-table-column label="大小">
                                <template slot-scope="scope">
                                    {{ scope.row.size | human_size }}
                                </template>
                            </el-table-column>
                            <el-table-column label="下载地址">
                                <template slot-scope="scope">
                                    <a v-if="scope.row.cdn_url" :href="scope.row.cdn_url" target="_blank"> <i class="el-icon-video-play"></i> 下载</a>
                                    <div v-else>
                                        <span class="text-muted" v-if="scope.row.retry < 10">尝试下载({{scope.row.retry}}</span>
                                        <span v-else class="text-danger" ><i class="el-icon-warning"></i> 下载失败，进入编辑重试</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                width="80"
                                label="录像ID">
                                <template slot-scope="scope">
                                    {{ scope.row.id }}
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="p-3">
                            <el-button type="default" icon="el-icon-refresh" @click="loadCouldRecordList"
                                       :disabled="editing">
                                重新载入
                            </el-button>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="报表下载" :disabled="meetingNotCreated">
                    <div class="">
                        <!--
                        <el-button size="medium" type="primary" icon="el-icon-question" onload="process.loading"
                                   @click="searchMeetings" :loading="process.loading">点击查询课程
                        </el-button>
                        -->
                        <el-select ref="meetings" v-model="uuid" placeholder="请选择" size="medium" class="mr-3 mt-2"
                                   filterable
                                   style="width: 16em"
                                   no-data-text="暂无记录">
                            <el-option
                                v-for="item in filter_meetings"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <el-button size="medium" type="info" icon="el-icon-download" onload="process.loading"
                                   @click="downloadReport" :loading="process.loading">下载报告
                        </el-button>
                    </div>
                    <el-divider></el-divider>
                    <div ref="excel_table">
                        <el-table class="table table-bordered" style="height: 20px;overflow: auto;">
                            <tbody>
                            <tr v-for="(x,i) in table" :key="'r'+i">
                                <td v-for="(y,ii) in x" :key="'d'+ii" v-html="y"></td>
                            </tr>
                            </tbody>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="学期选择" :disabled="meetingNotCreated">
                    <el-select v-model="semester_index" size="small" class="w-100" @change="handleChangeSemester">
                        <el-option v-for="(x,id) in semester_options" :key="id" :value="id" :label="x.name"><span
                            class="d-inline-block" style="width:200px;">{{ x.name }}</span>
                            <span class="text-success">{{ x.start_date }} - {{ x.end_date }}</span></el-option>
                    </el-select>
                </el-tab-pane>
            </el-tabs>
        </el-form>

        <el-dialog :visible.sync="dialog_recording_add" width="70%" :title="(record.id?'修改':'添加')+'课程录像'" destroy-on-close>
            <el-row class="px-3">
                <div role="alert" class="el-message el-message--info mb-3"
                     style="position: relative;top:-15px; padding: 5px">
                    <i class="el-message__icon el-icon-info"></i>
                    <p class="el-message__content">上传视频的过程中，可以先填写录像日期和时间。上传完成后，会自动保存。
                    </p>
                </div>
                <el-col :span="24" style="min-height: 80px;">
                    <fake-form-item :width="100" label="上传录像">
                        <div v-if="record.video || record.url">
                            <video width="360" height="180" :src="record.video?record.video.video_url:record.url"
                                   controls="controls">
                                your browser does not support the video tag
                            </video>
                            <br/>
                            <el-button type="danger" size="mini" icon="el-icon-delete" class="mt-2"
                                       @click="handleRecordRemoveVideo">删除视频重传
                            </el-button>
                        </div>
                        <div v-else>
                            <vue-slice-uploader
                                ref="recording_uploader"
                                @on-complete="handleRecordingSuccess"
                                @on-failed="handleRecordingUploadError"
                                :action="recording_upload_url"
                                :auto="true"
                                :multiple="false">
                            </vue-slice-uploader>
                        </div>
                    </fake-form-item>
                </el-col>
                <el-col :span="24">
                    <fake-form-item :width="100" label="录像日期" required>
                        <el-select v-model="record.date" placeholder="选择日期" class="w-100">
                            <el-option v-for="x in filter_schedule" :key="x.date+' '+x.course_time.substr(0,5)"
                                       :value="x.date+' '+x.course_time.substr(0,5)"
                                       :label="x.date+' '+x.course_time.substr(0,5)"></el-option>
                        </el-select>
                    </fake-form-item>
                </el-col>
                <el-col :span="24">
                    <fake-form-item :width="100" label="录像说明">
                        <el-input v-model="record.memo" placeholder="输入该录像的说明" clearable></el-input>
                    </fake-form-item>
                </el-col>
                <el-col :span="24">
                    <fake-form-item :width="100" label="外部连接">
                        <el-input v-model="record.url" placeholder="当视频文件在其他服务器上，请留下连接，否则留空白" clearable></el-input>
                    </fake-form-item>
                </el-col>
            </el-row>
            <div slot="footer" class="">
                <el-button @click="doRecordingEditCancel">{{ '放弃' }}</el-button>
                <el-button type="primary" icon="el-icon-upload" :loading="process.loading" @click="doRecordingUpload">
                    {{ ((record.id ? '修改' : '上传')) }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="cloud_record_dialog" width="70%" title="将云端录像转入课程录像" destroy-on-close>
            <el-row class="px-3">
                <el-col :span="24" style="min-height: 80px;">
                    <fake-form-item :width="100" label="云端录像">
                        <div v-if="cloud_record.cdn_url" :title="cloud_record.cdn_url">
                            <video width="360" height="180" :src="cloud_record.cdn_url" controls="controls">
                                your browser does not support the video tag
                            </video>
                        </div>
                        <div v-else-if="cloud_record.retry < 10">下载中,请等待 ({{cloud_record.retry}})</div>
                        <div v-else>
                            <i class="el-icon-warning"></i>下载失败!请重新下载。
                        </div>
                    </fake-form-item>
                </el-col>
                <el-col :span="24">
                    <fake-form-item :width="100" label="录像日期" required>
                        <el-select v-model="cloud_record.record_date" placeholder="选择日期" class="w-100">
                            <el-option v-for="x in filter_schedule" :key="x.date+' '+x.course_time.substr(0,5)"
                                       :value="x.date+' '+x.course_time.substr(0,5)"
                                       :label="x.date+' '+x.course_time.substr(0,5)"></el-option>
                        </el-select>
                    </fake-form-item>
                </el-col>
                <el-col :span="24">
                    <fake-form-item :width="100" label="录像说明">
                        <el-input v-model="cloud_record.subject" placeholder="输入该录像的说明" clearable></el-input>
                    </fake-form-item>
                </el-col>
                <el-col :span="24">
                    <fake-form-item :width="100" label="信息">
                        <div class="el-input">
                            <div class="el-input__inner bg-light">录制时间:<b class="text-info"> {{
                                    cloud_record.record_time
                                }} </b>| 文件大小:<b>{{ cloud_record.size | human_size }}</b></div>
                        </div>
                    </fake-form-item>
                </el-col>
            </el-row>
            <div slot="footer" class="">
                <el-button type="warning" @click="redoUpload" :loading="process.loading" v-if="user.role_level >= 2 && cloud_record.retry > 9 && !cloud_record.cdn_url" class="pull-left" icon="el-icon-refresh">
                    重新下载视频
                </el-button>
                <el-button @click="cloud_record_dialog=false">放弃</el-button>
                <el-button type="primary" icon="el-icon-refresh" :loading="process.loading" v-if="cloud_record.cdn_url"
                           @click="doCloudRecordingUpload">
                    转入课程录像
                </el-button>
            </div>
        </el-dialog>
    </el-row>
</template>

<script>
import Editor from "@/views/mixins/editor.mixin";

export default {
    mixins: [Editor]
}
</script>

